<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="rtl"
    :src="drawerImage ? image : ''"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <template
      v-if="drawerImage"
    >
      <v-img
        :key="image"
        :gradient="gradients[1]"
      />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <default-list :items="items" />
    </div>

    <template #append>
      <div class="pa-4 text-center">
        
      </div>
    </template>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>

  export default {
    name: 'DefaultDrawer',

    components: {
      DefaultDrawerHeader: () => import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
      DefaultList: () => import(
        /* webpackChunkName: "default-list" */
        './List'
      ),
    },
    data() {
      return {
        dark: true,
        gradients: [
            'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
            'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
            'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
          ],
        image: null,
        items: [
            {
              title: 'Dashboard',
              icon: 'mdi-view-dashboard',
              to: '/',
            },    
            
          ],
        
      }
    },
    computed: {
      mini() {
        return this.$store.state.mini;
      },
      drawer: {
        get () {
          return this.$store.state.drawer;
        },
        set (value) {
          this.$store.commit('changeDrawer', value);
        },
      },
      drawerImage() {
        return this.$store.state.drawerImage;
      },
      rtl() {
        return this.$store.state.rtl;
      },
    },
  }
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
